
import { computed, defineComponent, ref } from 'vue';
import { lostelkUrl } from '../../global';
export default defineComponent({
  props: {
    fileId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const fileDownloadUrl = computed(() => `${lostelkUrl}/files/${props.fileId}/download`);
    const downloadLink = ref();

    const download = async () => {
      downloadLink.value.click();
    };
    return {
      download,
      fileDownloadUrl,
      downloadLink,
    };
  },
});
