
import { defineComponent, ref } from 'vue';
import store from '../../store';
import _ from 'lodash';
import { lostelkUrl } from '@/global';
import router from '../../router';

export default defineComponent({
  setup() {
    const searchVal = ref();
    const tagCardList = ref();
    const tagCardTotal = ref(0);
    const colorCardList = ref();
    const colorCardTotal = ref(0);
    const userCardList = ref();
    const userCardTotal = ref(0);

    // 骨架屏显示控制变量
    const skeletonIsShow = ref(false);
    // 没有找到相关内容显示控制变量
    const noDataIsShow = ref(false);
    // 搜索弹窗显示控制变量
    const searchPopUpIsShow = ref(false);

    /**
     * 重置搜索状态
     */
    const reset = () => {
      searchPopUpIsShow.value = false;
      skeletonIsShow.value = false;
      noDataIsShow.value = false;
      tagCardList.value = null;
      tagCardTotal.value = 0;
      colorCardList.value = null;
      colorCardTotal.value = 0;
      userCardList.value = null;
      userCardTotal.value = 0;
    };

    /**
     * 实时搜索
     */
    const searchBrief = async () => {
      if (searchVal.value) {
        skeletonIsShow.value = true;
        searchPopUpIsShow.value = true;
        noDataIsShow.value = false;
        store.commit('setIsShowLoading', false);
        const tagRes = await store.dispatch('getSearchValCardBriefList', {
          val: searchVal.value.replace(/#/g, '%23'),
          type: 'tag'
        });
        store.commit('setIsShowLoading', false);
        const colorRes = await store.dispatch('getSearchValCardBriefList', {
          val: searchVal.value.replace(/#/g, '%23'),
          type: 'color'
        });
        store.commit('setIsShowLoading', false);
        const userRes = await store.dispatch('getSearchValCardBriefList', {
          val: searchVal.value.replace(/#/g, '%23'),
          type: 'user'
        });
        tagCardList.value = tagRes.data.slice(0, 4);
        tagCardTotal.value = tagRes.headers['x-total-count'];
        colorCardList.value = colorRes.data.slice(0, 4);
        colorCardTotal.value = colorRes.headers['x-total-count'];
        userCardList.value = userRes.data.slice(0, 4);
        userCardTotal.value = userRes.headers['x-total-count'];
        noDataIsShow.value = true;
        setTimeout(() => {
          skeletonIsShow.value = false;
        }, 300);
      } else {
        // 重置
        reset();
      }
    };

    /**
     * 实时搜索节流方法
     */
    const searchBriefFun = _.debounce(searchBrief, 500);

    /**
     * 查看所有相关
     */
    const allRelevant = (type: string) => {
      router.push(`/search/${type}/${searchVal.value}`);
      // 重置
      searchVal.value = null;
      reset();
    };

    /**
     * 查看单个内容
     */
    const aSinglePicture = (type: string, id: string) => {
      switch (type) {
        case 'tag':
          router.push(`/search/${type}/${searchVal.value}/searchCard/${id}`);
          break;
        case 'color':
          router.push(`/search/${type}/${searchVal.value}/searchCard/${id}`);
          break;
        case 'user':
          router.push(`/@${id}`);
          break;
        default:
          break;
      }
      // 重置
      searchVal.value = null;
      reset();
    };

    const search = () => {
      if (searchVal.value) {
        // console.log(1);
      }
    };
    return {
      search,
      searchVal,
      searchBrief,
      searchBriefFun,
      tagCardList,
      tagCardTotal,
      colorCardList,
      colorCardTotal,
      userCardList,
      userCardTotal,
      lostelkUrl,
      skeletonIsShow,
      noDataIsShow,
      searchPopUpIsShow,
      allRelevant,
      aSinglePicture
    };
  }
});
