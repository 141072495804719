
import { computed, defineComponent, ref } from 'vue';
import { lostelkUrl } from '../../global';
import router from '../../router';
import store from '../../store';
export default defineComponent({
  setup() {
    const typeOpen = ref(false);
    const type = ref('tag');
    const typeName = ref('Tag');
    const typeList = [
      { name: 'Tag', type: 'tag' },
      { name: 'Color', type: 'color' },
      { name: 'User', type: 'user' }
    ];
    const typeSwitch = () => {
      typeOpen.value = !typeOpen.value;
    };
    const selectType = (val: { type: string; name: string }) => {
      type.value = val.type;
      typeName.value = val.name;
      typeOpen.value = !typeOpen.value;
    };

    const searchVal = ref();
    const search = () => {
      if (searchVal.value) {
        switch (type.value) {
          case 'tag':
            router.push(`/search/tag/${searchVal.value.replace(/#/g, '$23')}`);
            break;
          case 'color':
            router.push(
              `/search/color/${searchVal.value.replace(/#/g, '%23')}`
            );
            break;
          case 'user':
            router.push(`/search/user/${searchVal.value.replace(/#/g, '%23')}`);
            break;
          default:
            break;
        }
      }
    };

    // 获取精选横图
    const transverse = computed(() => store.state.veryGoodsTransverseList);
    // 生成数组下标随机数
    const random = computed(() =>
      Math.floor(Math.random() * transverse.value.length)
    );

    return {
      lostelkUrl,
      search,
      type,
      typeList,
      typeSwitch,
      selectType,
      searchVal,
      typeName,
      typeOpen,
      transverse,
      random
    };
  }
});
