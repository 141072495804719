
import { computed, defineComponent } from 'vue';
import router from '../../router';
import store from '../../store';
export default defineComponent({
  props: {
    prompt: {
      type: String,
      default: 'No results found',
    },
  },
  setup(props) {
    const promptProps = computed(() => props.prompt);
    /**
     * 重新加载全部照片
     */
    const goBack = () => {
      router.push('/');
      store.commit('setSearchFailure', false);
      store.commit('mainSearchIsNone', true);
    };

    return {
      goBack,
      promptProps,
    };
  },
});
