
import { computed, defineComponent, PropType } from 'vue';
import { useStore } from 'vuex';
import router from '../../router';
import { useRoute } from 'vue-router';
import DropDownList from '../globalFun/DropDownList.vue';
import HeaderSearch from '../search/HeaderSearch.vue';
export interface UserMode {
  isLogin: boolean;
  name?: string;
  id?: number;
}

export default defineComponent({
  name: 'HeaderBox',
  components: {
    DropDownList,
    HeaderSearch,
  },
  props: {
    user: {
      type: Object as PropType<UserMode>,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const logout = () => {
      store.commit('logout');
    };
    const userId = computed(() => {
      return store.state.user.id;
    });

    /**
     * 重新加载全部照片并回到首页
     */
    const goBack = () => {
      if (route.path != '/') {
        router.push('/');
        store.commit('mainSearchIsNone', true);
        store.commit('setSearchFailure', false);
      }
    };

    return {
      logout,
      goBack,
      userId,
    };
  },
});
