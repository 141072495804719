
import { defineComponent, onUnmounted } from 'vue';

export default defineComponent({
  setup() {
    const node = document.createElement('div');
    node.id = 'loading';
    document.body.appendChild(node);

    onUnmounted(() => {
      document.body.removeChild(node);
    });
  },
});
