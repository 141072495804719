
import { computed, defineComponent } from 'vue';
import { axios } from '../../service/service';
import store from '../../store';
export default defineComponent({
  emits: ['singleCardReviseLike'],
  props: {
    isLike: Number,
    likeCount: {
      type: Number,
      required: false,
    },
    cardId: Number,
    singleCard: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const likedCount = computed(() => props.likeCount);
    const isLiked = computed(() => props.isLike);
    const singleCard = computed(() => props.singleCard);
    const giveLike = () => {
      store.commit('setIsShowLoading', false);
      if (!isLiked.value) {
        store.commit('clickLike', props.cardId);
        if (singleCard.value) {
          context.emit('singleCardReviseLike', 1);
        }
        axios.post(`/posts/${props.cardId}/like`);
      } else {
        store.commit('cancelLike', props.cardId);
        if (singleCard.value) {
          context.emit('singleCardReviseLike', 0);
        }
        axios.delete(`/posts/${props.cardId}/like`);
      }
    };
    return {
      giveLike,
      likedCount,
      isLiked,
    };
  },
});
